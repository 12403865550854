import { LaunchdarklyService } from '@acesso-io/launchdarkly-client-ts/lib/launchdarkly';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FeatureFlagService } from '@app/core/_shared/services/feature-flag/feature-flag.service';
import { GoogleAnalyticsEventsService } from '@app/core/_shared/services/google-analytics/google-analytics-events.service';
import * as fromStore from '@app/modules/admission/_store';
import { EncryptService } from '@app/modules/admission/admission-profile/_shared/services/encrypt/encrypt.service';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';


declare let environment: any;

@Component({
  selector: 'app-sidebar-account',
  templateUrl: './sidebar-account.component.html',
  styleUrls: ['./sidebar-account.component.scss']
})
export class SidebarAccountComponent implements OnInit {
  private readonly signUrl: string = environment.sign_url;
  private readonly baseUrl: string = environment.base_url;
  private readonly ongoingUrl: string = environment.url_ongoing_dashboard;
  private readonly benefits: string = environment.benefits_url;
  private sign_people_product_id: string = environment.sign_people_product_id;
  showBenefitsProductivity = false;

  @Input()
  set openMenu(open: boolean) {
    this.showMenu = open;
  }
  @Output() menu = new EventEmitter<boolean>();
  @Output() load = new EventEmitter<boolean>();

  url: string = environment.cdn_url;
  list: any;
  query: any;
  acc: any;
  showMenu = false;
  company = false;
  reports = false;
  hasReportsPermission = false;
  hasCollaborators = false;
  hasSignature = false;
  hasBackupEnabled$: Observable<any>;
  ongoingUrlList: string;
  benefitsURL: string = this.benefits + '/productivity';
  enableEnvelopesManagement = false;
  enableNewModelsList = false;

  constructor(
    public router: Router,
    private encryptService: EncryptService,
    private store: Store<fromStore.DashboardState>,
    private eventGa: GoogleAnalyticsEventsService,
    private featureFlagService: FeatureFlagService,
    private launchDarklyService: LaunchdarklyService,
  ) {}

  ngOnInit() {
    this.store.pipe(select(fromStore.getOrganizationState)).subscribe(res => {
      this.list = res.groups;
      this.acc = res.currentAccount;
    });

    this.store.pipe(select(fromStore.getModulesEntities)).subscribe(res => {
      this.hasCollaborators = res.collaborators;
      this.hasSignature = res.signature;
    });

    this.hasBackupEnabled$ = this.featureFlagService.hasFlag({
      flag_key: 'data-backup-enabled',
      entity_id: 'c1d93ec9-78aa-4a47-ac9d-ae0476b7abd8',
      context: {
          organization_id: localStorage.getItem('organization')
      }
    })

    this.ongoingUrlList = `${this.ongoingUrl}/all?a=${localStorage.getItem('currentAccount')}`;

    this.checkFlag(
      "people-rh_test_productivity",
      "6ffff7c5-58a5-4331-97b6-3c905d6e812a"
    );

    this.enableEnvelopesManagement = this.checkFeatureFlag('people-rh-envelopes-management');
    this.enableNewModelsList = this.checkFeatureFlag('people-rh-new-settings-models-list');
  }

  checkFlag(key: string, id: string ): void {
    this.featureFlagService.hasFlag({
      flag_key: key,
      entity_id: id,
      context: {
        organization_id: localStorage.getItem('organization')
      }
    }).subscribe(flag => {
      if (flag?.match === true) {
        this.showBenefitsProductivity = true;
      }
    })
  }

  checkFeatureFlag(flag): boolean {
    return this.launchDarklyService.evaluateBoolean({flagKey: flag});
  }

  toggleMenu() {
    this.showMenu = !this.showMenu;
    this.company = false;
    this.reports = false;
    this.menu.emit(this.showMenu);
  }

  changeCompany(uid) {
    const companies = this.list.reduce((all, item) => {
      item.accounts.map(res => {
        all.push(res);
      });
      return all;
    }, []);
    const account = companies.filter(x => x.uid === uid)[0];
    this.store.dispatch(new fromStore.ChangeAccout(account));
    localStorage.setItem('currentAccount', uid);
    this.router.navigate(['/']);
    location.reload();
  }

  toggleTab(event) {
    switch (event) {
      case 'reports':
        this.company = false;
        this.reports = !this.reports;
        break;
      case 'settings':
        this.reports = false;
        this.company = !this.company;
        break;
      default:
        break;
    }
  }

  trackerEvent() {
    this.eventGa.emitEvent('Menu Lateral', 'Click Assinatura Eletrônica', this.acc.name);
    this.toggleMenu();
  }

  redirectToSign() {
    this.trackerEvent();
    const payload = {
      showMenu: true
    };

    const encodedPayload = encodeURIComponent(
        this.encryptService.encrypt(JSON.stringify(payload))
    );
    window.location.href = `${this.signUrl}/login-integration?product_id=${this.sign_people_product_id}&redirect_uri=${this.baseUrl}&token=${encodedPayload}`;
  }
}
